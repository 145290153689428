import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  const formPadding = {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(12),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(12),
  };

  return {
    messageContainer: ({ type }) => ({
      backgroundColor:
        (type === 'error' && theme.palette.RED050) ||
        (type === 'success' && theme.palette.GREEN050) ||
        theme.palette.NEUTRAL020,
      ...formPadding,
    }),
    messageTitle: ({ isSmall }) => ({
      fontSize: theme.typography.pxToRem(isSmall ? 28 : 40),
      lineHeight: theme.typography.pxToRem(40),
      fontWeight: 'lighter',
    }),
    messageText: {
      marginBottom: 0,
      fontSize: '1.125rem',
    },
  };
});

const TitleMessage = ({ title, message, type }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ type, isSmall });

  return (
    <div className={classes.messageContainer}>
      <span className={classes.messageTitle}>{title}</span>
      <p className={classes.messageText}>{message}</p>
    </div>
  );
};

export default TitleMessage;
