import React from 'react';
import classNames from '@sindresorhus/class-names';
import { AlertBox, CheckboxMarked, Information } from 'mdi-material-ui';
import { Box } from '@material-ui/core';

// ASSETS
import useStyles from './Alert.styles';

const Alert = ({
  appearance,
  hideIcon = false,
  title,
  actions,
  noShadow = false,
}) => {
  // HOOKS
  const classes = useStyles();

  const isInfo = appearance === 'info';
  const isSuccess = appearance === 'success';
  const isDanger = appearance === 'danger';
  const isWarning = appearance === 'warning';

  // STYLES
  const cssAlert = classNames(
    classes.alert,
    { [classes.alertInfo]: isInfo },
    { [classes.alertSuccess]: isSuccess },
    { [classes.alertDanger]: isDanger },
    { [classes.alertWarning]: isWarning },
    { [classes.noShadow]: noShadow },
  );

  return (
    <div className={classes.alertContainer}>
      <div className={cssAlert} role="alert">
        <div className={classes.content}>
          <div className={classes.titleContainer}>
            {!hideIcon && (
              <>
                <Box mr={2}>
                  {isInfo && <Information />}
                  {isSuccess && <CheckboxMarked />}
                  {(isDanger || isWarning) && <AlertBox />}
                </Box>
              </>
            )}
            <span className={classes.title}>{title}</span>
          </div>
          <div className={classes.actions}>{actions}</div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
