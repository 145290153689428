import React from 'react';
import { makeStyles } from '@material-ui/styles';
import makeValidHexColor from '../../../utils/makeValidHexColor';

const useStyles = makeStyles((theme) => ({
  container: ({ background_color, background_image_url }) => {
    const cleaned_background_color = makeValidHexColor(background_color);
    return {
      height: '100%',
      backgroundColor: cleaned_background_color || theme.palette.NEUTRAL080,
      backgroundImage: background_image_url && `url("${background_image_url}")`,
      backgroundSize: background_image_url && 'cover',
      backgroundRepeat: background_image_url && 'no-repeat',
      backgroundPosition: background_image_url && 'center',
    };
  },
}));

const RegistrationHero = ({ product }) => {
  const classes = useStyles(product?.registration?.hero || {});

  return <div className={classes.container}></div>;
};

export default RegistrationHero;
