import { datadogRum } from '@datadog/browser-rum';
import buildEnvironment from './buildEnvironment';

const datadogEnabled = () => {
  const applicationId = process.env.REACT_APP_DATADOG_APP_ID;
  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;

  return Boolean(applicationId && clientToken);
};

const initDatadog = () => {
  const applicationId = process.env.REACT_APP_DATADOG_APP_ID;
  const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
  const version = process.env.REACT_APP_RELEASE_SHA;
  const upstreamApiUrl = process.env.REACT_APP_API_URL;
  const env = buildEnvironment.environment;

  if (!datadogEnabled()) return;

  datadogRum.init({
    applicationId,
    clientToken,
    env,
    site: 'datadoghq.com',
    service: 'tackle-registration',
    version,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [upstreamApiUrl],
  });

  datadogRum.startSessionReplayRecording();
};

export { initDatadog };
export default initDatadog;
