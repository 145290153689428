import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    minHeight: '100vh',
    height: '100%',
  },
  half: {
    flex: 1,
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  previewBannerShim: {
    width: '100%',
    height: theme.spacing(6),
  },
  previewBanner: {
    position: 'fixed',
    width: '100%',
    display: 'flex',
    height: theme.spacing(6),
    backgroundColor: theme.palette.BLUE500,
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
  },
  previewText: {
    color: theme.palette.NEUTRAL000,
    fontSize: '18px',
    fontWeight: '500',
    paddingLeft: theme.spacing(10),
  },
  buttonContainer: {
    paddingRight: theme.spacing(5),
  },
  previewButton: {
    height: '32px',
  },
}));

export default useStyles;
