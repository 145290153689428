import React, { useContext } from 'react';
import RegistrationContext from '../../../contexts/RegistrationContext';
import useStyles from './RegistrationHeader.styles';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

const RegistrationHeader = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ isSmall });

  const {
    state: { product, vendor },
  } = useContext(RegistrationContext);

  return (
    <div className={classes.logoContainer}>
      <img
        src={
          product.registration?.form?.logo_url ||
          vendor.logo_url ||
          product.logo
        }
        alt={product.name}
        className={classes.logo}
      />
    </div>
  );
};

export default RegistrationHeader;
