import { makeStyles } from '@material-ui/styles';
import makeValidHexColor from '../../../utils/makeValidHexColor';

const useStyles = makeStyles((theme) => ({
  container: ({ background_color }) => {
    const cleaned_background_color = makeValidHexColor(background_color);
    return {
      height: '100%',
      backgroundColor:
        cleaned_background_color || theme.palette.background.paper,
    };
  },
  formContainer: {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(12),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(12),
  },
  inputContainer: {
    paddingBottom: theme.spacing(3),
    width: '100%',
  },
}));

export default useStyles;
