import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import useStyles from './Register.styles';
import RegistrationForm from './RegistrationForm';
import RegistrationHero from './RegistrationHero';
import RegistrationContext, {
  actions,
} from '../../contexts/RegistrationContext';
import {
  getProduct,
  getSessionDetails,
  register,
} from '../../services/upstreamApiService';
import { FriendlyHTTPError } from '../../utils/errors';
import Spinner from '../../components/Spinner/Spinner';
//import Button from '../../components/Button';

const REDIRECT_WAIT_MS = 15000;

const PREVIEW_TEXT = 'Preview Mode';
//const PREVIEW_CONFIGURE_BUTTON_TEXT = 'CONFIGURE';

const Register = ({ preview = false }) => {
  let classes = useStyles();
  const {
    state: { product, registrationSuccess },
    doDispatch,
  } = useContext(RegistrationContext);

  const { vendorid, productid, customerid } = useParams();

  const { search, pathname } = useLocation();
  const history = useHistory();

  const qs = new URLSearchParams(search);
  const sessionId = qs.get('sessionId');

  const [alertError, setAlertError] = useState(null);
  const [productLoading, setProductLoading] = useState(true);
  const [redirectUri, setRedirectUri] = useState(null);
  const [sessionLoading, setSessionLoading] = useState(true);

  const handleSessionLoad = () => {
    if (!sessionId) {
      return setSessionLoading(false);
    }

    setSessionLoading(true);
    getSessionDetails(sessionId)
      .then(({ data }) => {
        doDispatch(actions.GET_SESSION_SUCCESS, data);
        setSessionLoading(false);
      })
      .catch((error) => {
        doDispatch(actions.GET_SESSION_FAILURE, error);
        setSessionLoading(false);
        history.replace(pathname);
      });
  };

  const handleLoad = () => {
    setProductLoading(true);

    getProduct(vendorid, productid)
      .then((result) => {
        doDispatch(actions.GET_PRODUCT_SUCCESS, result);

        setProductLoading(false);
      })
      .catch(() => {
        setProductLoading(false);

        // TODO: Sentry error here
        history.push('/');
      });
  };

  const generateRedirectUri = useCallback(() => {
    const redirect = product?.registration?.success?.redirect_url;
    const session = registrationSuccess?.session;

    if (redirect) {
      let url = new URL(redirect);

      if (session) {
        url.searchParams.set('sessionid', session);
      }

      const redirectUrl = url.toString();
      setRedirectUri(redirectUrl);

      return redirectUrl;
    }
  }, [
    product?.registration?.success?.redirect_url,
    registrationSuccess?.session,
  ]);

  const handleRedirect = (uri) => {
    setTimeout(() => {
      window.location.href = uri;
    }, REDIRECT_WAIT_MS);
  };

  const handleSuccessfulRegistration = () => {
    const uri = generateRedirectUri();
    if (uri && registrationSuccess && !preview) {
      handleRedirect(uri);
    }
  };

  const previewRegister = () => {
    doDispatch(actions.REGISTRATION_SUCCESS);
  };

  const handleRegister = (orderProps) => {
    doDispatch(actions.REGISTRATION_SUBMITTED);

    register(vendorid, productid, customerid, orderProps)
      .then((result) => {
        doDispatch(actions.REGISTRATION_SUCCESS, result);

        if (alertError) {
          setAlertError(null);
        }
      })
      .catch((e) => {
        doDispatch(actions.REGISTRATION_FAILURE, e);

        const error = new FriendlyHTTPError(e);

        // TODO: Sentry error here
        setAlertError({
          appearance: 'danger',
          message: error.message,
          ctaButton: error.ctaButton,
        });
      });
  };

  useEffect(handleLoad, [vendorid, productid, history, doDispatch]);
  useEffect(handleSuccessfulRegistration, [
    registrationSuccess,
    product,
    preview,
    generateRedirectUri,
  ]);
  useEffect(handleSessionLoad, [
    search,
    doDispatch,
    history,
    pathname,
    sessionId,
  ]);

  if (productLoading || sessionLoading) {
    return (
      <div className={classes.spinner}>
        <Spinner />
      </div>
    );
  }

  const previewBanner = () => {
    return (
      <div className={classes.previewBannerShim}>
        <div className={classes.previewBanner}>
          <div className={classes.previewText}>{PREVIEW_TEXT}</div>
          {/* <div className={classes.buttonContainer}>
            <Button
              className={classes.previewButton}
              appearance='white'
              onClick={() => history.goBack()}
            >
              {PREVIEW_CONFIGURE_BUTTON_TEXT}
            </Button>
          </div> */}
        </div>
      </div>
    );
  };

  return (
    <div>
      {preview && previewBanner()}
      <div className={classes.container}>
        <div className={classes.half}>
          <RegistrationForm
            onRegister={preview ? previewRegister : handleRegister}
            alertError={alertError}
            setAlertError={setAlertError}
            redirectUri={redirectUri}
          />
        </div>
        <div className={classes.half}>
          <RegistrationHero product={product} />
        </div>
      </div>
    </div>
  );
};

export default Register;
