import React, { useContext, useState } from 'react';

import RegistrationContext from '../../../contexts/RegistrationContext';
import Layout from './RegistrationForm.layout';
import { login } from '../../../services/azureADService';
import { AZURE, CLOUDS, VALIDATION_TYPE_EMAIL } from '../../../utils/constants';
import VALIDATORS from '../../../utils/validators';
import { RegistrationUser } from '../../../utils/registrationUser';

const REQUIRED_ERROR = 'This field is required';
const SUBMIT_BUTTON_TEXT = 'COMPLETE REGISTRATION';
const VALIDATION_ERRORS = {
  [VALIDATION_TYPE_EMAIL]: 'Please enter a valid email',
  default: 'Input is invalid',
};

const RegistrationForm = ({
  onRegister,
  alertError,
  setAlertError,
  redirectUri,
}) => {
  const {
    state: { product, session },
  } = useContext(RegistrationContext);

  const [registrationData, setRegistrationData] = useState(
    session?.pre_registration_details ?? {},
  );
  const [registrationErrors, setRegistrationError] = useState({});

  const enabledFields = (product?.registration_page_fields || []).filter(
    ({ enabled }) => enabled,
  );

  const handleChange = (name) => (event) => {
    const field = enabledFields.find(({ title }) => title === name);
    const value = event.target.value;

    if (field.required === true && !value.length) {
      setRegistrationError({
        ...registrationErrors,
        [name]: REQUIRED_ERROR,
      });
    } else if (
      typeof VALIDATORS[field.validation_type] === 'function' &&
      !VALIDATORS[field.validation_type](value)
    ) {
      setRegistrationError({
        ...registrationErrors,
        [name]:
          VALIDATION_ERRORS[field.validation_type] || VALIDATION_ERRORS.default,
      });
    } else {
      setRegistrationData({
        ...registrationData,
        [name]: value,
      });

      delete registrationErrors[name];
    }
  };

  const handleAutofill = async (cloud) => {
    // Reset any alerts
    setAlertError();

    if (!CLOUDS[cloud]) {
      return;
    }

    if (CLOUDS[cloud] === AZURE) {
      try {
        const profile = await login();
        const data = RegistrationUser(enabledFields, profile, AZURE);
        setRegistrationData(data);
        // Reset field errors
        setRegistrationError({});
      } catch (e) {
        setAlertError({
          appearance: 'danger',
          message: e.message,
          ctaButton: e.ctaButton,
        });
      }
    }
  };

  const handleSubmit = () => {
    const fieldErrors = { ...registrationErrors };

    enabledFields.forEach((field) => {
      const title = field.title;

      if (field.required && !registrationData[title]) {
        fieldErrors[title] = REQUIRED_ERROR;
      }
    });

    setRegistrationError(fieldErrors);

    if (Object.keys(fieldErrors).length) {
      return;
    }

    const orderProps = Object.keys(registrationData).reduce(
      (orderProps, fieldTitle) => {
        orderProps[fieldTitle] = registrationData[fieldTitle];

        return orderProps;
      },
      {},
    );

    onRegister(orderProps);
  };

  return (
    <Layout
      fields={enabledFields}
      registrationData={registrationData}
      registrationErrors={registrationErrors}
      handleChange={handleChange}
      handleAutofill={handleAutofill}
      onSubmit={handleSubmit}
      submitButtonText={SUBMIT_BUTTON_TEXT}
      alertError={alertError}
      redirectUri={redirectUri}
    />
  );
};

export default RegistrationForm;
