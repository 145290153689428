import { init } from '@fullstory/browser';

import buildEnvironment, { PRODUCTION_ENV } from './buildEnvironment';

const env = buildEnvironment.environment;
const organizationId = process.env.REACT_APP_FULLSTORY_ORGANIZATION_ID;
const configOptions = {};

const fullStoryEnabled = () => {
  return env === PRODUCTION_ENV && !!organizationId;
};

const initFullStory = () => {
  if (!fullStoryEnabled()) return;

  init({
    orgId: organizationId,
    ...configOptions,
  });
};

export { initFullStory };
export default initFullStory;
