import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import initDatadog from './utils/datadog';
import initFullStory from './utils/fullstory';

import './index.css';
import App from './App';

if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

initDatadog();
initFullStory();

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
