export const PRODUCTION_ENV = 'production';
export const STAGING_ENV = 'staging';
export const LOCAL_UNKNOWN_ENV = 'local/unknown';

const buildEnvironment = {
  environment:
    (process.env.REACT_APP_BUILD_CIRCLECI && 'production') ||
    (process.env.REACT_APP_BUILD_CLOUDFLARE && 'staging') ||
    'local/unknown',
};

export default buildEnvironment;
