import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(12),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(12),
  },
  poweredByContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
  },
  poweredBy: {
    height: theme.typography.pxToRem(75),
  },
  copyrightContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  cloudLogo: {
    height: theme.typography.pxToRem(20),
  },
  textBottom: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.NEUTRAL100,
  },
  policyLinks: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.palette.BLUE400,
  },
}));

export default useStyles;
