import React, { useReducer, useCallback } from 'react';

const initialState = {
  product: {},
  vendor: {},
  session: null,
  registrationError: undefined,
  registrationSuccess: undefined,
  registrationLoading: false,
};

const RegistrationContext = React.createContext(initialState);
RegistrationContext.displayName = 'RegistrationContext';

export const actions = {
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
  GET_PRODUCT_FAILURE: 'GET_PRODUCT_FAILURE',
  GET_SESSION_SUCCESS: 'GET_SESSION_SUCCESS',
  GET_SESSION_FAILURE: 'GET_SESSION_FAILURE',
  REGISTRATION_SUBMITTED: 'REGISTRATION_SUBMITTED',
  REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
  REGISTRATION_FAILURE: 'REGISTRATION_FAILURE',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.value.product,
        vendor: action.value.vendor,
      };

    case actions.GET_SESSION_SUCCESS:
      return {
        ...state,
        session: action.value,
      };

    case actions.REGISTRATION_SUBMITTED:
      return {
        ...state,
        registrationLoading: true,
      };

    case actions.REGISTRATION_SUCCESS:
      return {
        ...state,
        registrationSuccess: action.value,
        registrationLoading: false,
        registrationError: undefined,
      };

    case actions.REGISTRATION_FAILURE:
      return {
        ...state,
        registrationError: action.value,
        registrationLoading: false,
      };

    default:
      return state;
  }
};

export const RegistrationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const doDispatch = useCallback(
    (action, value = {}) =>
      dispatch({
        type: action,
        value,
      }),
    [],
  );

  return (
    <RegistrationContext.Provider value={{ state, doDispatch }}>
      {children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationContext;
