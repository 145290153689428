import { InputBase } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';

export const TextInputBase = withStyles((theme) => ({
  input: {
    padding: '10px 12px',
    width: '100%',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #DADCE0',
    borderRadius: 4,
    backgroundColor: '#fff',
    fontFamily: theme.typography.fontFamily,
    '&:focus': {
      borderColor: theme.palette.BLUE400,
    },
  },
}))(InputBase);

export const useTextFieldStyles = makeStyles((theme) => ({
  inputBase: {
    width: '100%',
    padding: 0,
  },
  inputLabel: {
    position: 'static',
    marginBottom: 10,
    color: theme.palette.NEUTRAL300,
    transform: 'translate(0, 1.5px) scale(0.80)',
    lineHeight: 1.25,
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-focused': {
      color: theme.palette.BLUE400,
    },
  },
  required: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  inputContainer: {
    display: 'flex',
    position: 'relative',
  },
  inputDisabled: {
    '& input, & textarea': {
      color: 'rgba(0, 0, 0, 0.36)',
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
    'select&': {
      color: 'rgba(0, 0, 0, 0.36)',
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
  },
  inputError: {
    '& input, & textarea': {
      borderWidth: '2px',
      borderColor: theme.palette.RED400,
    },
  },
  inputAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputGroupAppend: {
    display: 'flex',
    marginLeft: -1,

    '& button': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  inputBtn: {
    position: 'relative',
    zIndex: 2,
    cursor: 'pointer',
    color: '#6c757d',
    borderColor: '#DADCE0',
    display: 'inline-block',
    textAlign: 'center',
    verticalAlign: 'middle',
    userSelect: 'none',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    fontSize: '1rem',
    lineHeight: theme.typography.pxToRem(1.5),
    borderRadius: '.25rem',
    maxHeight: 41,
    padding: '9px 10px',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  helperTextContainer: {
    flex: '1 1 auto',
  },
  smallSize: {
    '& input, & textarea': {
      padding: '5.5px 12px',
    },
  },
}));
