import React, { useContext } from 'react';
import { Form, Field } from 'react-final-form';
import { Box } from '@material-ui/core';
import { Select } from '@tackle-io/platform-ui';

import useStyles from './RegForm.styles';
import TextField from '../../../../components/TextField';
import Button from '../../../../components/Button';
import RegistrationContext from '../../../../contexts/RegistrationContext';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import makeValidHexColor from '../../../../utils/makeValidHexColor';

const RegForm = ({
  fields,
  registrationData,
  registrationErrors,
  handleChange,
  onSubmit,
  submitButtonText,
}) => {
  const {
    state: { product, vendor, registrationLoading },
  } = useContext(RegistrationContext);

  const formStyles = product.registration?.form || {};
  const classes = useStyles(formStyles);

  const buttonBackgroundColor =
    makeValidHexColor(formStyles?.button?.background_color) || null;

  const buttonStyles = {
    main: buttonBackgroundColor,
    contrastText: formStyles?.button?.color,
    hover:
      makeValidHexColor(formStyles?.button?.hover?.background_color) ||
      (buttonBackgroundColor ? emphasize(buttonBackgroundColor, 0.1) : null),
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    onSubmit();
  };

  const getFieldTitle = (display_title, title, required = false) => {
    // if display_title = "   * Full Name* *"
    // then sanitized_label = "Full Name"
    const sanitized_label = (display_title || title).replace(
      /^[\s,*]+|[\s,*]+$/gi,
      '',
    );
    return `${sanitized_label}${required ? ' *' : ''}`;
  };

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={registrationData}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {fields.map(
              ({ data_type, display_title, title, options, required }) => (
                <Box
                  key={`${data_type}_${title}`}
                  className={classes.fieldContainer}
                >
                  <Field
                    key={`${data_type}_${title}`}
                    name={`${title}`}
                    component={data_type}
                  >
                    {(props) => {
                      const fieldTitle = getFieldTitle(
                        display_title,
                        title,
                        required,
                      );
                      const changeHandler = handleChange(title);

                      switch (data_type) {
                        case 'text':
                          return (
                            <TextField
                              {...props.input}
                              name={title}
                              label={fieldTitle}
                              onBlur={changeHandler}
                              error={registrationErrors[title]}
                              autoComplete="off"
                            />
                          );

                        case 'dropdown':
                          return (
                            <Select
                              {...props.input}
                              name={title}
                              label={fieldTitle}
                              onChange={changeHandler}
                              error={registrationErrors[title]}
                            >
                              {!registrationData[title] ? (
                                <option></option>
                              ) : null}
                              {options.map(({ label, value }) => (
                                <option key={`${title}-${value}`} value={value}>
                                  {label}
                                </option>
                              ))}
                            </Select>
                          );

                        default:
                          return null;
                      }
                    }}
                  </Field>
                </Box>
              ),
            )}

            <Box className={classes.buttonContainer}>
              <Button
                palette={buttonStyles}
                onClick={handleOnSubmit}
                loading={registrationLoading}
                fullWidth
                type="submit"
              >
                {submitButtonText}
              </Button>
            </Box>
          </form>
        )}
      />
      <div className={classes.privacyPolicyContainer}>
        {
          'By submitting this form, I agree that my information will be shared with '
        }
        <strong>{vendor.name}</strong>
        {' and '}
        <a
          href={'https://tackle.io'}
          target={'_blank'}
          rel={'noopener noreferrer'}
          className={classes.tackleLink}
        >
          {'Tackle.io'}
        </a>
        {' in accordance with the '}
        <a
          href={'https://tackle.io/privacy-policy/'}
          target={'_blank'}
          rel={'noopener noreferrer'}
          className={classes.policyLinks}
        >
          {'Privacy Policy'}
        </a>
        {' and '}
        <a
          href={'https://tackle.io/platform-terms-of-use/'}
          target={'_blank'}
          rel={'noopener noreferrer'}
          className={classes.policyLinks}
        >
          {'Terms of Use'}
        </a>
        {'.'}
      </div>
    </>
  );
};

export default RegForm;
